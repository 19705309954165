import type { ContainerWidth, ImageField, SectionElement } from './page-config.types';

export interface GlobalConfig {
  _id: string | null;
  agencyId: number | null;
  domain: string;
  theme?: string;
  mainNav: { variant: string };
  searchForm: SearchForm;
  externalScripts: {
    analytics: {
      gtmId?: string;
      gtmCustomEventNames: {
        viewingEnquiry?: string;
        instantValuation?: string;
        expertValuation?: string;
        branchEnquiry?: string;
        sendPropertyToFriend?: string;
        staffEnquiry?: string;
        userRegistration?: string;
        emailPortal?: string;
      };
      items: AnalyticsScriptItem[];
    };
    standalone: {
      items: ScriptItem[];
    };
  };
  generalSettings: {
    enableGoogleStaticMaps: boolean;
    disableHomeflowCookieConsent: boolean;
    useLegacyArticles: boolean;
  };
  propertyCard: { variant: string };
  sectionPadding: number;
  typography: Typography;
  footer: Footer;
  urls?: {
    branch?: string;
    branchList?: string;
  };
  customVariables?: {
    [name: string]: string;
  };
}

export enum FooterElementType {
  Link = 'link',
  SocialLink = 'socialLink',
  SocialBlock = 'socialBlock',
  Heading = 'heading',
  Divider = 'divider',
  Text = 'text',
  Image = 'image',
}

export enum FooterItemType {
  // Accreditation = 'accreditation',
  Image = 'image',
  Branch = 'branch',
  Social = 'social',
  NavList = 'navList',
  Text = 'text',
}

export enum SocialType {
  X = 'x',
  Facebook = 'facebook',
  Instagram = 'instagram',
  Pinterest = 'pintereset',
  LinkedIn = 'linkedIn',
  TikTok = 'tikTok',
  YouTube = 'youTube',
}

export interface FooterElement {
  id: string;
  type: FooterElementType;
  html?: string;
  level?: number;
  image?: ImageField;
  url?: string;
  target?: '_blank' | '_self';
  socialType?: SocialType;
  width?: number;
  height?: number;
  marginTop?: number;
  marginBottom?: number;
  color?: string;
  socialBlock?: SocialBlock;
  gap?: number;
}

export interface SocialBlock {
  facebook: { enable: boolean; url: string };
  youTube: { enable: boolean; url: string };
  x: { enable: boolean; url: string };
  linkedIn: { enable: boolean; url: string };
  tikTok: { enable: boolean; url: string };
  instagram: { enable: boolean; url: string };
  pinterest: { enable: boolean; url: string };
}

export interface FooterItem {
  id: string;
  type: FooterItemType;
  html?: string;
  orientation: 'columns' | 'rows';
  color?: string;
  justify?: Justify;
  gap?: number;
  elements?: FooterElement[];
}

export enum Justify {
  Start = 'start',
  End = 'end',
  Center = 'center',
  SpaceBetween = 'between',
  SpaceAround = 'around',
}

export interface FooterSection {
  id: string;
  items: FooterItem[];
  paddingTop?: number;
  paddingBottom?: number;
  justify: Justify;
  verticalAlign: 'start' | 'center' | 'end';
  color?: string;
  orientation: 'columns' | 'rows';
  gap: number;
  containerWidth: ContainerWidth;
}

export interface Footer {
  footerType: 'default' | 'custom';
  html?: string;
  css?: string;
  sections: FooterSection[];
}

export enum TypographyCase {
  Normal = 'normal',
  Upper = 'upper',
  Lower = 'lower',
}

export enum Overlap {
  None = 'none',
  Small = 'small',
  Large = 'large',
}

export interface SearchFormItem {
  type: 'select' | 'checkbox' | 'radio' | 'search-input' | 'input';
  labels: string[]; // an array to account for radio buttons
  options?: { value: string; label: string }[];
}

export enum ChannelOption {
  DefaultSales = 'defaultSales',
  DefaultLettings = 'defaultLettings',
  SalesOnly = 'salesOnly',
  LettingsOnly = 'lettingsOnly',
  Hidden = 'hidden',
}

export interface SearchForm {
  channel?: ChannelOption;
  minPrice?: boolean;
  maxPrice?: boolean;
  minBeds?: boolean;
  maxBeds?: boolean;
  geolocate?: boolean;
  expanded?: boolean;
  items?: SearchFormItem[];
  backgroundOpacity?: number;
  locationInputPlaceholder?: string;
  color?: string;
  /** If passed, any searches from this type of search form should be scoped to these tags */
  scopedTags?: string;
  activeSearchType?: string;
  customSearchForms?: {
    [name: string]: SectionElement;
  };
  overrideGlobal?: boolean;
}

export interface ScriptItem {
  id: string;
  content: string;
}

export interface AnalyticsScriptItem extends ScriptItem {
  category: 'analytics' | 'marketing';
  service: string;
}

export enum FontSource {
  Google = 'google',
  Link = 'link',
}

export interface Font {
  source: FontSource;
  names: string[];
  default: boolean;
  url?: string;
}

export interface GlobalConfigTypographyElement {
  font: { name: string; source: FontSource };
  case: TypographyCase;
  bold: boolean;
  italic: boolean;
  underline: boolean;
  size: number;
}

export interface Typography {
  fonts: Font[];
  body: GlobalConfigTypographyElement;
  h1: GlobalConfigTypographyElement;
  h2: GlobalConfigTypographyElement;
  h3: GlobalConfigTypographyElement;
  h4: GlobalConfigTypographyElement;
  h5: GlobalConfigTypographyElement;
  h6: GlobalConfigTypographyElement;
  nav: GlobalConfigTypographyElement;
}
