'use client';

import htmlToReact from '@/utils/html-to-react';
import { ClientSideOnly } from '@/lib/components';
import { GlobalConfig } from '@homeflow/next/state';
import { Fragment } from 'react';

export default function Standalone({ globalConfig }: { globalConfig: GlobalConfig }) {
  const { items } = globalConfig.externalScripts.standalone;
  const scripts = items.map((item) => ({ id: item.id, content: htmlToReact(item.content) }));

  return (
    <ClientSideOnly>
      {scripts.map((script) => (
        <Fragment key={script.id}>{script.content}</Fragment>
      ))}
    </ClientSideOnly>
  );
}
