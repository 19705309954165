import { Overlap, SearchForm } from './global-config.types';

export enum ContainerWidth {
  Full = 'full',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
}

/**
 * enum representing the core page types/routes. If you add or update this list you must
 * also update the reverse mapping object below.
 * */
export enum PageName {
  Home = 'home',
  Article = 'article',
  ArticleList = 'article-list',
  Branch = 'branch',
  BranchList = 'branch-list',
  Property = 'property',
  PropertyList = 'property-list',
  PropertyListInterstitial = 'property-list-interstitial',
  Staff = 'staff',
  StaffList = 'staff-list',
  Custom = 'custom',
  NotFound = 'not-found',
}

export interface PageConfig {
  _id: string | null;
  agencyId: number | null;
  sharedWith?: number[];
  page: PageName | null;
  metadata: {
    title?: string;
    description?: string;
    noindex?: boolean;
  };
  areas: {
    [key: string]: Section<MixedSection>[]; // Base config can be overriden with custom props and a union type
  };
  sectionOverrides?: {
    [key: number]: {
      [sectionId: string]: Section<MixedSection>;
    };
  };
  createdAt: string;
  updatedAt: string;
  resourceId?: number;
  slug?: string;
  live?: boolean;
  category?: string;
  title?: string;
  home?: HomeFields;
  article?: ArticleFields;
  articleList?: ArticleListFields;
  branch?: BranchFields;
  branchList?: BranchListFields;
  property?: PropertyFields;
  propertyList?: PropertyListFields;
  staff?: StaffFields;
  staffList?: StaffListFields;
  custom?: CustomFields;
  position?: number;
}

export interface HomeFields {}

export interface ArticleFields {
  /** Short promo text for the article */
  promo?: string;
  /** Image to display in article results */
  promoImage: ImageField;
  /** Array of topics as strings */
  topics?: string[];
  /** The date to display for the article, which may differ from the createdAt date */
  displayDate?: string;
}

export interface ArticleListFields {}
export interface BranchFields {
  branchId: string;
}
export interface BranchListFields {}
export interface PropertyFields {
  label: string;
  rules: PropertyRules;
}
export interface PropertyListFields {
  interstitialAsBanner: boolean;
  label: string;
  rules: PropertyListRules;
}
export interface StaffFields {}
export interface StaffListFields {}
export interface CustomFields {
  slug: string;
  category: string;
  live: boolean;
  title: string;
  pageWidth?: ContainerWidth;
}

export interface PropertyRules {
  primaryChannel?: string;
  propertyType?: string;
  tags?: string[];
  hasChildProperties?: boolean;
}

export interface PropertyListRules {
  channel: string;
  tags?: string[];
}

export type TextField = {
  html: string;
};

export type HeadingField = TextField & {
  level?: number;
};

export type ImageField = {
  src?: string;
  alt: string;
  svg?: string;
  type?: string;
  lazy?: boolean;
  position?: string;
  objectFit?: string;
  hidden?: boolean;
  width?: number;
  height?: number;
};

export type LinkField = TextField & {
  url?: string;
  target?: string;
};

export enum ElementType {
  Heading = 'heading',
  Text = 'text',
  Link = 'link',
  SearchForm = 'searchForm',
  BranchSearchForm = 'branchSearchForm',
  Social = 'social',
  Image = 'image',
  Divider = 'divider',
}

export const elementTypeLabels = {
  [ElementType.Heading]: 'Heading',
  [ElementType.Link]: 'Link button',
  [ElementType.Social]: 'Social',
  [ElementType.Text]: 'Text',
  [ElementType.SearchForm]: 'Search Form',
  [ElementType.BranchSearchForm]: 'Branch search form',
  [ElementType.Image]: 'Image',
  [ElementType.Divider]: 'Divider',
};

export enum ButtonVariant {
  Default = 'default',
  Primary = 'primary',
  PrimaryBorder = 'primaryBorder',
  Tint = 'tint',
  TintBorder = 'tintBorder',
  WhiteBorder = 'whiteBorder',
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g',
}
export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type SocialLink = {
  url: string;
  target: '_blank' | '_self';
};

export type SocialElement = {
  email: SocialLink;
  phone: SocialLink;
  facebook: SocialLink;
  instagram: SocialLink;
  x: SocialLink;
  linkedIn: SocialLink;
  pinterest: SocialLink;
};

export interface SectionElement extends SearchForm {
  id: string;
  type: ElementType;
  html?: string;
  buttonVariant?: ButtonVariant;
  buttonSize?: ButtonSize;
  level?: number;
  icon?: string;
  url?: string;
  image?: ImageField;
  social?: SocialElement;
  target?: string;
  color?: string;
  marginBottom?: number;
  marginTop?: number;
}

export enum SectionHeight {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface GenericConfig {
  id: string;
  paddingTop?: number;
  overlapTop?: Overlap;
  paddingBottom?: number;
  overlapBottom?: Overlap;
  containerWidth?: ContainerWidth;
  zIndex?: number;
  variant?: string;
  image?: ImageField;
  elements: SectionElement[];
  link?: LinkField & { hidden: boolean };
  items?: GenericConfig[];
  customCss?: string;
  count?: number;
  height?: SectionHeight;
  /** The IDs of campaigns to which the section is linked */
  campaignIds?: string[];
  /** The IDs of campaigns the section should be inactive during */
  campaignStatus?: 'active' | 'inactive';
  backgroundColor?: string;
}

// TODO: should this change to have nested structure like the GenericConfig?
export interface ImageBannerConfig extends GenericConfig {
  overlayOpacity: number;
}

export interface CustomFormConfig extends GenericConfig {
  formId: string;
  formElements: { fullWidth: boolean }[];
}

export interface CarouselConfig extends GenericConfig {
  transition?: string;
  overlayOpacity?: number;
  items: GenericConfig[];
  delay?: number;
}

export interface PropertySliderConfig extends GenericConfig {
  channel?: string;
  heading?: HeadingField;
  subheading?: HeadingField;
  tags?: string;
  branchId?: number;
}

export interface StaffConfig extends GenericConfig {
  searchIds: number[];
}

export interface MapConfig extends GenericConfig {
  mapType: string;
  lat: number;
  lng: number;
}

export interface ImageWithTextConfig extends GenericConfig {
  textWidth: string;
}

export interface MultiItemConfig extends GenericConfig {
  columns?: number;
}

export interface RichTextConfig extends GenericConfig {
  html?: string;
}

export interface TestimonialsConfig extends GenericConfig {
  channel?: string;
}

export interface VideoBannerConfig extends ImageBannerConfig {
  src: string;
  autoplay: boolean;
  controls: boolean;
  muted: boolean;
  loop: boolean;
}

export interface CustomPagesConfig extends GenericConfig {
  category?: string;
}

export interface Section<T> {
  _id?: string;
  component: string;
  id: string;
  configuration: T;
  name?: string; // custom name used for identifying shared sections
  sharedSection?: string | Section<T>;
}

export type MixedSection =
  | GenericConfig
  | ImageBannerConfig
  | CarouselConfig
  | PropertySliderConfig
  | StaffConfig
  | RichTextConfig
  | VideoBannerConfig
  | ImageWithTextConfig
  | CustomFormConfig;
