import { StaticImageData } from 'next/image';

import { ElementType, GlobalConfig, MixedSection, Section, SectionElement } from '../state';

export type SectionWrapperProps<T> = {
  editMode?: boolean;
  section: Section<T> & { areaName: string };
};

export type SectionProps<T> = SectionWrapperProps<T> & {
  globalConfig?: GlobalConfig;
  SectionElementWrapper?: ISectionElementWrapper;
};

export type SectionElementProps = {
  editMode?: boolean;
  element: SectionElement;
  elementIndex: number;
  baseClassName?: string;
  section: Section<MixedSection> & { areaName: string };
  itemIdChain?: string[];
  SectionElementWrapper?: ISectionElementWrapper;
  globalConfig?: GlobalConfig;
};

export type ISectionElementWrapper = (
  props: SectionElementProps & { children: React.ReactNode }
) => JSX.Element;

export enum ConfigSettingType {
  Boolean = 'boolean',
  Text = 'text',
  Array = 'array',
  Image = 'image',
  Select = 'select',
  MultiSelect = 'multiSelect',
  MultiOption = 'multiOption',
  Slider = 'slider',
  RichText = 'richText',
  SimpleRichText = 'simpleRichText',
  CodeCSS = 'codeCss',
  CodeJS = 'codeJs',
  Textarea = 'textarea',
  TextInput = 'textInput',
  ColorPicker = 'colorPicker',
  Number = 'number',
  NumberSlider = 'numberSlider',
  ButtonVariantPicker = 'buttonVariantPicker',
  Branch = 'branch',
  MultiTextInput = 'multiTextInput',
}

export type ConfigSetting = {
  label: string;
  description?: string;
  type: ConfigSettingType;
  defaultValue?: string;
  field: string;
  options?: ConfigOption[];
  configSettings?: ConfigSetting[];
  sliderConfig?: SliderConfig;
  // Boolean options
  trueLabel?: string;
  falseLabel?: string;

  // Array options
  fixedSize?: boolean;
  placeholder?: string;
  defaultArrayItem?: MixedSection;
  itemLabel?: string;
};
export type ConfigOption = { label: string; value: string };

export type SliderConfig = { min: number; max: number; step: number; defaultValue?: number };

export type Elements = {
  [key in ElementType]?: {
    limit: number;
  };
};
export type ItemsSchema = {
  limit: number;
  elements: Elements;
  items?: ItemsSchema;
};

export type SerializableSectionSchema = {
  label: string;
  description: string;
  previewImage?: StaticImageData;
  configSettings: ConfigSetting[];
  elements: Elements;
  items?: ItemsSchema;
  /** Settings specific to a given variant */
  variantSettings?: {
    [key: string]: ConfigSetting[];
  };
};

export type SectionSchema<
  T = MixedSection,
  C = (props: SectionWrapperProps<T>) => JSX.Element,
> = SerializableSectionSchema & {
  icon: () => React.ReactNode;
  clientComponent: C;
  initialConfig: () => T;
  settingsSummary?: (agencyData: { subdomain?: string }) => React.ReactNode;
};
