'use client';

import htmlToReact from '@/utils/html-to-react';
import { ClientSideOnly } from '@/lib/components';
import { GlobalConfig } from '@homeflow/next/state';
import Script from 'next/script';
import { Fragment } from 'react';

export default function Analytics({ globalConfig }: { globalConfig: GlobalConfig }) {
  const { items, gtmId } = globalConfig.externalScripts.analytics;
  const scripts = items.map((item) => ({
    id: item.id,
    content: htmlToReact(item.content, {
      customScript: { category: item.category, service: item.service, id: item.id },
    }),
  }));

  return (
    <ClientSideOnly>
      {/* Custom scripts */}
      {scripts.map((script) => (
        <Fragment key={script.id}>{script.content}</Fragment>
      ))}
      {gtmId && (
        <>
          {/* GTM scripts */}
          <Script id="gtm-initialization" type="text/javascript" strategy="afterInteractive">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtmId}');
            `}
          </Script>
          <Script id="datalayer-initialization" type="text/javascript" strategy="afterInteractive">
            {`
              // Define dataLayer and the gtag function.
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}

              // Set default consent to 'denied' as a placeholder
              // Determine actual values based on your own requirements
              gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
              });
            `}
          </Script>
          <Script
            id="gtm-ad-storage"
            async
            data-category="analytics"
            data-service="Ad storage"
            type="text/plain"
          >
            {`
              gtag('consent', 'update', {
                'ad_storage': 'granted',
                'wait_for_update': 500
              });
            `}
          </Script>
          <Script
            id="gtm-ad-user-data"
            async
            data-category="analytics"
            data-service="Ad user data"
            type="text/plain"
          >
            {`
              gtag('consent', 'update', {
                'ad_user_data': 'granted',
                'wait_for_update': 500
              });
            `}
          </Script>
          <Script
            id="gtm-ad-personalization"
            async
            data-category="analytics"
            data-service="Ad personalisation"
            type="text/plain"
          >
            {`
              gtag('consent', 'update', {
                'ad_personalization': 'granted',
                'wait_for_update': 500
              });
            `}
          </Script>
          <Script
            id="gtm-ad-analytics"
            async
            data-category="analytics"
            data-service="Analytics storage"
            type="text/plain"
          >
            {`
              gtag('consent', 'update', {
                'analytics_storage': 'granted',
                'wait_for_update': 500
              });
            `}
          </Script>
        </>
      )}
    </ClientSideOnly>
  );
}
