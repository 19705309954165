import { MixedSection, Section, SectionElement } from '@homeflow/next/state';
import { CSSProperties } from 'react';

/** Takes a section configuration and builds a style object for the color properties */
export default function colorStyleFromConfig(
  configuration: Section<MixedSection>['configuration']
) {
  const styles: CSSProperties = {};

  // at the moment we only have backgroundColor on sections
  if (configuration?.backgroundColor)
    styles.backgroundColor = colorValue(configuration.backgroundColor);

  return styles;
}

/** Takes a string and returns it as a variable if it isn't a hex value */
export function colorValue(value: string) {
  // if it's a hex value or 'white', use it directly
  if (value.startsWith('#') || value === 'white') return value;

  // if not, we need to grab the variable
  return `var(--${value}-color)`;
}
