import React, { PropsWithChildren, useEffect, useState } from 'react';

/**
 * This wrapper provides a fix to hydration errors caused by client components
 * that rely on redux state being pre-rendered on the server
 */

export default function ClientSideOnly({
  children,
  fallback,
}: {
  fallback?: JSX.Element;
} & PropsWithChildren): JSX.Element | null {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return fallback || null;
  }

  // We have to wrap children in a fragment here to return a JSX Element as TS won't let us return
  // a ReactNode on its own. But ESLint doesn't like 'useless' fragments so we have to disable.
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
